body{
    height: 100vh;
    width: 100%;
    background-color: #161B2F;
    color: white;
    scroll-behavior: smooth;
    overflow-x: hidden;
    /* background-image: url('./img/Snow.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
}
*{
  font-family: 'Consolas';
}
/* Custom scrollbar for WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 12px; /* Width of the entire scrollbar */
  }
  
  ::-webkit-scrollbar-track {
    background: #161B2F; /* Color of the track */
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #6b8cbf; /* Color of the scroll thumb */
    border-radius: 10px;
    border: 3px solid #161B2F; /* Padding around the thumb */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #5a7aa6; /* Color of the scroll thumb on hover */
  }
  
  /* Custom scrollbar for Firefox */
  body {
    scrollbar-width: thin;
    scrollbar-color: #6b8cbf #161B2F;
  }
  
  /* Custom scrollbar track for Firefox */
  body::-moz-scrollbar-track {
    background: #161B2F;
  }
  
  /* Custom scrollbar thumb for Firefox */
  body::-moz-scrollbar-thumb {
    background-color: #6b8cbf;
    border-radius: 10px;
    border: 3px solid #161B2F;
  }
  
  body::-moz-scrollbar-thumb:hover {
    background-color: #5a7aa6;
  }
  
  /* Apply the scrollbar styles to specific containers */
  .skills-container {
    max-height: 300px; /* Set a maximum height for the container */
    overflow-y: auto; /* Enable vertical scrolling */
    padding-right: 10px; /* Add padding to the right to avoid overlap */
  }
  
  /* Apply the scrollbar styles to the whole document */
  body {
    overflow-y: scroll; /* Ensure the whole document is scrollable */
  }
  
.secondary-color{
    color:#6b8cbf;
}
.secondary-bg{
    background-color: #6b8cbf;
}
.otherfont{
    font-size: large;

}
#Nav{
    background: transparent;
} 

#navtext{
    font-size: large;
    color: white;
    align-items: end;
    cursor: pointer;
}
#navtext:hover{
    color: #6b8cbf;
}
.banner {
    height: 100vh;
    width: 100%;
    background-image: url('./img/banner.svg');
    background-repeat: no-repeat;
    background-size: cover;
}
.btitle{
  font-size: 3.5rem;
}
.btext{
  font-size: 2.4rem;
}
@media screen and (max-width: 768px) {
    .banner{
        height: 100vh;
        width: 100%;
        background-image: url('./img/banner.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .btitle{
        font-size: 3rem;
    }
    .btext{
        font-size: 2rem;
    }
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.3); /* Light border for glass effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Blur effect for frosted glass */
  border-radius: 10px; /* Optional: Rounded corners */
  padding: 20px; /* Optional: Padding inside the container */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.skill-card {

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  padding: 0 5px;
  }
  @media (max-width: 768px) {
    .skills-container {
      flex-direction: column;
      flex-wrap: nowrap;
        }
    
    .skill-card {
      gap: 5px;
      align-items: flex-start;
    }
    .skill-card img{
      width: 45px;
        height: 45px;
    }

  }
  .footer {
    background-color: #161B2F;
    color: white;
    padding: 20px 0;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .footer-content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-text {
    margin: 0;
    font-size: 1rem;
    color: #f1f1f1;
  }
  
  .footer-links {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .footer-link {
    color: #6b8cbf;
    margin: 0 15px;
    text-decoration: none;
    font-size: 1.1rem;
  }
  
  .footer-link:hover {
    color: #ffffff;
  }
  .card1 {
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }
  @media screen and (max-width: 768px) {
    .card1{
      height: 100%;
    }
}
  .card-title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .card-text {
    color: #f1f1f1;
  }
  
  .btn-primary {
    background-color: #6b8cbf;
    border-color: #6b8cbf;
  }
  
  .btn-primary:hover {
    background-color: #5a7aa6;
    border-color: #5a7aa6;
  }
  .contact-container {
    padding: 20px;
    color: white;
  }
  
  .contact-details, .contact-form {
    background: rgba(255, 255, 255, 0.1); /* Semi-transparent background for glass effect */
    backdrop-filter: blur(10px); /* Blur effect for frosted glass */
    border: solid 1px rgba(255, 255, 255, 0.3); /* Light border for glass effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Padding inside the sections */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
    margin: 10px; /* Margin between the sections */
  }
  
  .contact-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: fit-content;
  }
  
  .contact-form {
    flex: 2;
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: rgba(22, 27, 47, 0.9); /* Slightly transparent input background for glass effect */
    color: white;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #6b8cbf;
    outline: none;
  }
  
  .btn-primary {
    background-color: #6b8cbf;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    color: white;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .btn-primary:hover {
    background-color: #5a7aa6;
  }
  
  .contact-details p {
    margin: 10px 0;
  }
  
.resume {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.left {
  flex: 1;
  padding: 20px;
}

.right {
  flex: 2;
  padding: 20px;
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: calc(100vh - 160px); /* Adjust the max height as needed */
}


.info {
  margin-bottom: 20px;
}

.buttons a {
  display: inline-block;
  margin-right: 10px;
  color: #ffffff;
  text-decoration: none;
}

.buttons a i {
  margin-right: 5px;
}

.experience, .skills, .soft-skills, .certificate {
  margin-bottom: 20px;
}

.h1 {
  font-size: 24px;
  margin-bottom: 10px;
  border-bottom: 2px solid #6b8cbf; /* Secondary color for underline */
}


h4 {
  margin: 10px 0;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}
.right::-webkit-scrollbar {
  width: 12px;
}

.right::-webkit-scrollbar-track {
  background: #161B2F;
  border-radius: 10px;
}

.right::-webkit-scrollbar-thumb {
  background-color: #6b8cbf;
  border-radius: 10px;
  border: 3px solid #161B2F;
}

.right::-webkit-scrollbar-thumb:hover {
  background-color: #5a7aa6;
}

.right {
  scrollbar-width: thin;
  scrollbar-color: #6b8cbf #161B2F;
}

.right::-moz-scrollbar-track {
  background: #161B2F;
}

.right::-moz-scrollbar-thumb {
  background-color: #6b8cbf;
  border-radius: 10px;
  border: 3px solid #161B2F;
}

.right::-moz-scrollbar-thumb:hover {
  background-color: #5a7aa6;
}
.glass-effect {
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background for glass effect */
  backdrop-filter: blur(10px); /* Blur effect for frosted glass */
  border: solid 1px rgba(255, 255, 255, 0.3); /* Light border for glass effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Padding inside the sections */
  margin-bottom: 20px; /* Margin between sections */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.button-box {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-direction: column;
}
@media (max-width: 768px) {
  .resume {
    flex-direction: column;
  }
  
  .left, .right {
    width: 100%;
  }
  
  .button-box {
    text-align: center;
  }
}
/* Ensure the toggle button is on the right */
.navbar-toggler {
  margin-left: auto;
}

/* Customize the navbar links */
.nav-link {
  color: #fff; /* Change this to your desired color */
}

/* Change the color of the navbar links on hover */
.nav-link:hover {
  color: #6b8cbf; /* Change this to your desired hover color */
}
@media (max-width: 768px) {
  .contact-container {
    padding: 10px;
  }

  .contact-details,
  .contact-form {
    padding: 10px;
    margin: 5px 0;
  }

  .contact-details {
    width: 100%;
    margin-bottom: 20px;
  }

  .contact-form {
    width: 100%;
  }

  .form-group input,
  .form-group textarea {
    padding: 8px;
  }

  .btn-primary {
    padding: 8px 16px;
    font-size: 0.875rem;
  }
}